<template>
  <v-dialog width="1000" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        v-on="on"
        block
        height="30px"
        @click="fetchTattooers"
        v-text="$t('edit', { name: '' })"
      />
    </template>
    <v-card>
      <v-card-title>
        {{ $tc("artists") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th width="200" class="px-0">Artista</th>
            <th width="100" class="px-0">¿Servicio?</th>
            <th width="100" class="px-0">¿Online?</th>
            <th width="140" class="px-0">Tiempo (min)</th>
            <th width="120" class="px-0">Nº Sesiones</th>
            <th width="150" class="px-0">Paga y señal</th>
            <th width="100" class="px-0">Precio</th>
            <th width="120" class="px-0">Cobrar antes</th>
          </tr>
        </thead>
        <tbody v-if="tattooers > 0">
          <tr v-for="(t, i) in tattooers" :key="i">
            <td class="px-1">{{ t.user.fullname }}</td>
            <td>
              <v-select
                :items="yesNo"
                outlined
                dense
                hide-details
                v-model="t.service"
              />
            </td>
            <td>
              <v-select
                :items="yesNo"
                outlined
                dense
                hide-details
                v-model="t.online"
                :disabled="!t.service"
              />
            </td>
            <td>
              <v-text-field
                outlined
                dense
                hide-details
                v-model="t.time"
                :disabled="!t.service"
              />
            </td>
            <td>
              <v-text-field
                outlined
                dense
                hide-details
                v-model="t.sesions"
                :disabled="!t.service"
              />
            </td>
            <td>
              <v-text-field
                outlined
                dense
                hide-details
                v-model="t.price"
                :disabled="!t.service"
              />
            </td>
            <td>
              <v-text-field
                outlined
                dense
                hide-details
                v-model="t.paymentSignalPrice"
                :disabled="!t.service"
              />
            </td>
            <td width="120">
              <v-select
                style="max-width: 120px"
                :items="options_pay(t)"
                outlined
                dense
                hide-details
                v-model="t.marketplace_pay"
                :disabled="!t.online"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="text-center">
              {{ $t("no_artist_subservice") }}
            </td>
          </tr>
        </tbody></v-simple-table
      >

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          @click="dialog = false"
          style="height: 25px; width: 100px"
          elevation="0"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          @click="save"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ArtistSubServiceConfiguration",
  props: ["subservice"],
  data() {
    return {
      dialog: false,
      tattooers: [],
    };
  },
  methods: {
    options_pay(ss) {
      return [
        { value: "nothing", text: this.$t("services.marketplaces.nothing") },
        {
          value: "token_payment",
          text: this.$t("services.marketplaces.token_payment", {
            price:
              ss.paymentSignalPrice > 0
                ? this.$n(ss.paymentSignalPrice, "currency")
                : null,
          }),
        },
        {
          value: "price",
          text: this.$t("services.marketplaces.price", {
            price:
              ss.price * ss.sesions > 0
                ? this.$n(ss.price * ss.sesions, "currency")
                : null,
          }),
        },
      ];
    },
    ...mapActions("tattooers", ["getTattooers"]),
    fetchTattooers() {
      this.getTattooers({
        filters: {
          type_artist: this.subservice.parent_id,
          studio_id: this.$store.state.auth.user.studio.id,
        },
        pagination: { itemsPerPage: -1 },
      }).then((response) => {
        this.tattooers = response.data.map((t) => {
          let service = false;
          let online = false;
          let ss = this.subservice;
          let a = this.subservice.users.find((u) => u.id == t.id);
          if (a) {
            console.log(a);
            service = true;
            online = a.pivot.online == 1;
            ss = a.pivot;
          }
          //delete ss.id;
          return { ...t, ...ss, id: t.id, service, online };
        });
      });
    },
    ...mapActions("subservices", ["attachTattooersSubService"]),
    save() {
      this.attachTattooersSubService({
        service_id: this.subservice.parent_id,
        sub_service_id: this.subservice.id,
        tattooers: this.tattooers,
      }).then((response) => {
        this.dialog = false;
        this.$alert(this.$t("save_ok"));
        this.$emit("update");
      });
    },
  },
  computed: {
    ...mapGetters("app", ["yesNo"]),
  },
};
</script>
